import { Box, IconButton, useTheme , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext } from "../../../theme";
import { useNavigate } from "react-router-dom";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";


const Topbar = () => {
  const theme = useTheme();
  const colorMode: any = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // State to control dialog open/close

  const handleLogoutClick = () => {
    setOpen(true); // Open the confirmation dialog
  };
  const handleLogoutCancel = () => {
    setOpen(false); // Close the dialog
  };

  const handleLogoutConfirm = () => {
    setOpen(false); // Close the dialog

    localStorage.clear();
    window.location.href ="/";

  };

  const handleChangelogClick = () => {
    navigate("/changelog"); // Navigate to the changelog route
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box display="flex">
        {/*
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
        */}
      </Box>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={handleChangelogClick}> {/* Route to changelog */}
          <UpdateIcon /> {/* Update icon */}
        </IconButton>
        {/* <IconButton>
          <NotificationsIcon />
        </IconButton> */}
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleLogoutClick}> {/* Open dialog on click */}
          <LogoutOutlinedIcon /> {/* Logout icon */}
        </IconButton>
      </Box>

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={open}
        onClose={handleLogoutCancel} // Close the dialog if the user clicks outside or cancels
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogoutConfirm} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
    </Box>
    // </Box>
  );
};

export default Topbar;
