import { Box } from "@mui/material";
import AdviserUsersTable from "./adviser-users-table";


function AdviserUsers() {
  return (
    <Box sx={{ paddingBottom: "64px" }}>
      <AdviserUsersTable />
    </Box>
  );
}

export default AdviserUsers;
