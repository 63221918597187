import { makeAutoObservable } from "mobx";

class LoadingStore {
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.loading = value;
  }
}

const loadingStore = new LoadingStore();
export default loadingStore;
