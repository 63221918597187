import awsgateway from "aws/aws-gateway";
import axios, { AxiosRequestConfig } from "axios";
import loadingStore from "../loading";
import { enqueueSnackbar } from "notistack";

export type ApiType = "UserManagementApi";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

class RestUtils {

  private async apiInfo(type: ApiType): Promise<any> {
    const { API: { endpoints } } = awsgateway;
    return endpoints.find((endpoint) => endpoint.name === type);
  }

  async call<T>(type: ApiType, url: string, method: HttpMethod, body?: any): Promise<T> {
    loadingStore.setLoading(true);
    try {
      const apiInfo = await this.apiInfo(type);

      let headers: Record<any, any> | null = null;
      headers = await apiInfo.custom_header();
      const config: AxiosRequestConfig = {
        headers: {
          ...headers
        }
      };

      let response;
      url = await apiInfo.endpoint + "/" + url;
      switch (method) {
        case "POST":
          response = await axios.post<T>(url, body, config);
          break;
        case "PUT":
          response = await axios.put<T>(url, body, config);
          break;
        case "PATCH":
          response = await axios.patch<T>(url, body, config);
          break;
        case "DELETE":
          response = await axios.delete<T>(url, { ...config, data: body });
          break;
        case "GET":
        default:
          response = await axios.get<T>(url, config);
          break;
      }
      return response.data;
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(error?.response?.data?.details || (error?.response?.data?.message || "Internal server error !!!"), { variant: "error" });
      return null as any;
    } finally {
      loadingStore.setLoading(false);
    }
  }
}

const restUtils = new RestUtils();
export default restUtils;
